import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import {
  Catalogue,
  Customer,
  Invitations,
  LoginSteps,
  Offering,
  Place,
  Places,
  Reports,
  RightHolder,
  Right,
  Users
} from '../pages'
import { appRoutes, appSubRoutes } from './permissions'

import AppMenu from './appMenu'
import { MoovyAuthenticator } from '../components'
import MoovyRoute from './moovyRoute'
import useAuth from '../hooks/useAuth'
import useCustomerData from '../hooks/useCustomerData'
import {
  DISABLE_COGNITO_AUTHENTICATION,
  IS_DEVELOPMENT_ENVIRONMENT
} from '../envConfig'
import LocalAuthenticator from '../components/moovyAuthenticator/LocalAuthenticator'

const AppRoutes = () => {
  const { isPermission } = useAuth()
  const { customer, place, initialized, catalogueId } = useCustomerData()

  const RouteHome = () => {
    const showLoginRoute = !customer?.id || !place?.id
    const isRootPlaces = isPermission('READ', 'ADMIN_PLACES')
    const isRootPlace = !isRootPlaces && isPermission('READ', 'PLACES')

    return (
      <MoovyRoute
        isProgress={!initialized}
        isAllowed={!showLoginRoute}
        redirectPath={appRoutes.LOGIN_STEPS}
      >
        {isRootPlaces ? (
          <Navigate to={appRoutes.PLACES} replace />
        ) : isRootPlace ? (
          <Navigate to={`${appRoutes.PLACES}/${place.id}`} replace />
        ) : (
          <Navigate to={`${appRoutes.CATALOGUES}/${catalogueId}`} replace />
        )}
      </MoovyRoute>
    )
  }

  const MoovyRouters = () => {
    return (
      <Routes>
        <Route index element={<RouteHome />} />
        <Route
          path={appRoutes.INVITATIONS}
          element={
            <MoovyRoute
              isAllowed={isPermission('READ', 'LANDLORD_INVITATIONS')}
            >
              <Invitations />
            </MoovyRoute>
          }
        />
        <Route
          path={appRoutes.LOGIN_STEPS}
          element={
            <MoovyRoute>
              <LoginSteps />
            </MoovyRoute>
          }
        />
        <Route
          path={`${appRoutes.RIGHT_HOLDERS}/:rightHolderRef`}
          element={
            <MoovyRoute isAllowed={isPermission('READ', 'RIGHT_HOLDERS')}>
              <RightHolder />
            </MoovyRoute>
          }
        />
        <Route
          path={appRoutes.PLACES}
          element={
            <MoovyRoute isAllowed={isPermission('READ', 'ADMIN_PLACES')}>
              <Places />
            </MoovyRoute>
          }
        />
        <Route
          path={`${appRoutes.PLACES}/:placeParentRef${appSubRoutes.SUB_PLACES}/:placeRef`}
          element={
            <MoovyRoute isAllowed={isPermission('READ', 'PLACES')}>
              <Place />
            </MoovyRoute>
          }
        />
        <Route
          path={`${appRoutes.PLACES}/:placeRef`}
          element={
            <MoovyRoute isAllowed={isPermission('READ', 'PLACES')}>
              <Place />
            </MoovyRoute>
          }
        />
        <Route
          path={`${appRoutes.OFFERINGS}/:offeringId`}
          element={
            <MoovyRoute isAllowed={isPermission('READ', 'OFFERING')}>
              <Offering />
            </MoovyRoute>
          }
        />
        <Route
          path={`${appRoutes.CATALOGUES}/:catalogueRef`}
          element={
            <MoovyRoute>
              <Catalogue />
            </MoovyRoute>
          }
        />
        <Route
          path={`${appRoutes.CUSTOMERS}/:customerRef`}
          element={
            <MoovyRoute isAllowed={isPermission('READ', 'CUSTOMER')}>
              <Customer />
            </MoovyRoute>
          }
        />
        <Route
          path={appRoutes.REPORTS}
          element={
            <MoovyRoute
              isAllowed={
                isPermission('READ', 'CATALOGUE_REPORTS') ||
                isPermission('READ', 'RIGHT_REPORTS')
              }
            >
              <Reports />
            </MoovyRoute>
          }
        />
        <Route
          path={appRoutes.USERS}
          element={
            <MoovyRoute>
              <Users />
            </MoovyRoute>
          }
        />
        <Route
          path={`${appRoutes.RIGHTS}/:ref`}
          element={
            <MoovyRoute isAllowed={isPermission('READ', 'RIGHTS')}>
              <Right />
            </MoovyRoute>
          }
        />
        <Route path="*" element={<RouteHome />} />
      </Routes>
    )
  }

  return (
    <BrowserRouter>
      <AppMenu>
        {DISABLE_COGNITO_AUTHENTICATION && IS_DEVELOPMENT_ENVIRONMENT ? (
          <LocalAuthenticator>
            <MoovyRouters />
          </LocalAuthenticator>
        ) : (
          <MoovyAuthenticator>
            <MoovyRouters />
          </MoovyAuthenticator>
        )}
      </AppMenu>
    </BrowserRouter>
  )
}

export default AppRoutes
