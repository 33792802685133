import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { MoovyErrorPage, MoovyProgress } from '..'
import { QUERY_AUTH_GET_SESSION } from '../../apis/moovyQueryKeys'
import React, { useEffect } from 'react'

import { Box } from '@mui/material'

import LoginAPI from '../../apis/authenticate/loginAPI'
import useAuth from '../../hooks/useAuth'
import useCustomerData from '../../hooks/useCustomerData'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import useAuthenticatorData from './useAuthenticatorData'

const MoovyAuthenticator = ({ children }: { children?: React.ReactNode }) => {
  const { user } = useAuthenticator((context) => [context.user])
  const [searchParams] = useSearchParams()
  const signUp = searchParams.get('signUp')

  const { isAuthenticated, setAuthentication, userGroups } = useAuth()
  const { customer } = useCustomerData()

  const sessionQuery = useQuery({
    queryKey: [QUERY_AUTH_GET_SESSION],
    queryFn: () => LoginAPI.getAuthSession(),
    enabled: false
  })

  const { queryPlaces, queryCustomers, waitingForCustomers, waitingForPlaces } =
    useAuthenticatorData({
      customerId: customer?.id,
      queryCataloguesEnabled: sessionQuery.isSuccess,
      queryPlacesEnabled: sessionQuery.isSuccess,
      queryCustomersEnabled: sessionQuery.isSuccess
    })

  useEffect(() => {
    if (sessionQuery.isSuccess) {
      const jwtToken = LoginAPI.getJWTToken(sessionQuery.data)
      if (jwtToken) {
        setAuthentication({
          userGroups: LoginAPI.getUserGroups(sessionQuery.data),
          token: jwtToken
        })
        LoginAPI.setTokenToInterceptor(jwtToken)
      }
    }
  }, [sessionQuery.isSuccess])

  const waitingForSession = sessionQuery.isPending
  const waitingForGroups = userGroups === undefined

  // Login has happened, fetch session data to Redux state and Session storage
  useEffect(() => {
    user?.username && sessionQuery.refetch()
  }, [user?.username])

  return (
    <Box>
      <Authenticator
        initialState={signUp ? 'signUp' : 'signIn'}
        signUpAttributes={['given_name', 'family_name', 'email']}
      >
        {({ signOut, user }) => {
          return (
            <>
              {!isAuthenticated ||
              waitingForGroups ||
              waitingForSession ||
              waitingForCustomers ||
              waitingForPlaces ? (
                <MoovyProgress />
              ) : sessionQuery.isError ||
                queryPlaces.isError ||
                queryCustomers.isError ? (
                <MoovyErrorPage />
              ) : (
                children
              )}
            </>
          )
        }}
      </Authenticator>
    </Box>
  )
}

export default MoovyAuthenticator
