export const DEFAULT_CACHE_TIME_MS = 5 * 60 * 1000

export const QUERY_AUTH_GET_SESSION = 'getAuthSession'

export const QUERY_CATALOGUES_BY_REF = 'getCataloguesByRef'
export const QUERY_CATALOGUES_ALL = 'getCatalogues'
export const QUERY_CATALOGUES_BY_OFFERING = 'getCataloguesByOffering'
export const QUERY_CATALOGUES_FOR_LOGIN = 'getCataloguesForLogin'
export const QUERY_CATALOGUES_ACTIVE_RIGHTS = 'getCataloguesActiveRights'

export const QUERY_CUSTOMER_GET_ALL = 'getCustomers'
export const QUERY_CUSTOMER_GET_BY_REF = 'getCustomerByRef'

export const QUERY_INDIVIDUALS_GET_ALL_BY_CUSTOMER = 'getIndividuals'
export const QUERY_INDIVIDUALS_GET_BY_EMAIL = 'getIndividualsByEmail'

export const QUERY_INVITATION_GET_ADMINS = 'getAdminInvitationsById'
export const QUERY_INVITATION_GET_LANDLORDS = 'getLandlordInvitationsById'
export const QUERY_INVITATION_GET_TENANTS = 'getTenantInvitationsById'
export const QUERY_INVITATIONS_FOR_USER = 'getUserInvitations'

export const QUERY_LANDLORDS_FOR_LOGIN = 'getLandlordsForLogin'

export const QUERY_OFFERINGS_GET_BY_PLACE = 'getOfferingsByPlace'
export const QUERY_OFFERINGS_GET_BY_ID = 'getOfferingsById'

export const QUERY_PLACE_GET_ALL = 'getPlaces'
export const QUERY_PLACE_GET_BY_REF = 'getPlaceByRef'
export const QUERY_PLACES_FOR_LOGIN = 'getPlacesForLogin'
export const QUERY_PLACES_BY_CUSTOMER = 'getPlacesByCustomer'

export const QUERY_RIGHT_HOLDER_GET_BY_REF = 'getRightHolderByRef'

export const QUERY_RIGHT_GET_BY_REF = 'getRightByRef'

export const QUERY_USERS_ADMIN_GET_BY_REF = 'getAdminUsersByRef'
