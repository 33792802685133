import React, { useState } from 'react'
import loginAPI from '../../apis/authenticate/loginAPI'
import LoginAPI from '../../apis/authenticate/loginAPI'
import useAuth from '../../hooks/useAuth'
import { jwtDecode } from 'jwt-decode'
import { Alert, Box, Button, TextField } from '@mui/material'

const DevLogin = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const { setAuthentication } = useAuth()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError('')
    try {
      const token = await loginAPI.devLogin(email)
      const decoded: any = jwtDecode(token)
      setAuthentication({
        userGroups: decoded['cognito:groups']?.join(','),
        token: token
      })
      LoginAPI.setTokenToInterceptor(token)
    } catch (e: any) {
      setError(`${e.message}: ${e.response?.data?.error}`)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2
      }}
      noValidate
      autoComplete="off"
    >
      <h1>Local development login is enabled</h1>
      <p style={{ marginTop: 0 }}>
        Want to enable Cognito authentication? Set environment variable{' '}
        <code>DISABLE_COGNITO_AUTHENTICATION</code> to false.
      </p>
      <TextField
        required
        id="outlined-required"
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        helperText="Email to sign in with. Include word 'admin' to sign in with admin user."
      />
      <Button variant="contained" type="submit">
        Submit
      </Button>
      {error && <Alert severity="error">{error}</Alert>}
    </Box>
  )
}

export default DevLogin
