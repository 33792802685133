import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyDateTime,
  MoovyGrid,
  MoovyGridCard,
  MoovyLink,
  MoovyRestricted,
  MoovyTable
} from '../../../../components'
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { UseQueryResult } from '@tanstack/react-query'
import { UserDTO } from '../../../../apis/user/dto'
import { getJoinedValue } from '../../../../utils/utils'
import useAuth from '../../../../hooks/useAuth'

type TableAdministratorsProps = {
  queryAdministrators: UseQueryResult<UserDTO[], any>
  setShowModalRemove: ({ name, id }: { name: string; id: string }) => void
}

const TableAdministrators = ({
  queryAdministrators,
  setShowModalRemove
}: TableAdministratorsProps) => {
  const { isPermission, decodedToken } = useAuth()
  const intl = useIntl()
  const administrators: UserDTO[] = queryAdministrators.data || []

  // Check that logged in user can't delete his own administrator rights
  const isCurrentUserRow = (row: UserDTO) => row.id === decodedToken?.sub

  return (
    <>
      <MoovyGrid
        emptyTextId="page.customers.tabAdministrators.tableAdministrators.action.empty"
        errorTextId="page.customers.tabAdministrators.tableAdministrators.action.failed"
        query={queryAdministrators}
      >
        {administrators.map((row) => (
          <MoovyGridCard
            key={row.id}
            cardTitle={getJoinedValue(row.lastName, row.firstName)}
            cardSecondaryTitle={<MoovyDateTime utcDateTime={row.createdAt} />}
            cardContentList={[
              <FormattedMessage id="common.text.email" />,
              <b>{row.email}</b>,
              <FormattedMessage id="page.customers.tabAdministrators.tableAdministrators.header.registeredIn" />,
              <b>
                <MoovyDateTime utcDateTime={row.createdAt} emptyText="-" />
              </b>
            ]}
            cardMenuActions={
              isPermission('DELETE', 'USERS') && !isCurrentUserRow(row)
                ? [
                    {
                      itemText: intl.formatMessage({
                        id: 'page.customers.tabAdministrators.tableAdministrators.button.remove'
                      }),
                      onClick: () =>
                        setShowModalRemove({
                          name: getJoinedValue(row.lastName, row.firstName),
                          id: row.id
                        })
                    }
                  ]
                : undefined
            }
          />
        ))}
      </MoovyGrid>
      <MoovyTable
        items={administrators}
        emptyTextId="page.customers.tabAdministrators.tableAdministrators.action.empty"
        errorTextId="page.customers.tabAdministrators.tableAdministrators.action.failed"
        query={queryAdministrators}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="page.customers.tabAdministrators.tableAdministrators.header.name" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="common.text.email" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="page.customers.tabAdministrators.tableAdministrators.header.registeredIn" />
            </TableCell>
            <MoovyRestricted action={'DELETE'} resource={'USERS'}>
              <TableCell />
            </MoovyRestricted>
          </TableRow>
        </TableHead>
        <TableBody>
          {administrators.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {getJoinedValue(row.lastName, row.firstName)}
              </TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>
                <MoovyDateTime utcDateTime={row.createdAt} />
              </TableCell>
              <MoovyRestricted
                action={'DELETE'}
                resource={'USERS'}
                visibility={!!row.createdAt && !isCurrentUserRow(row)}
              >
                <TableCell align="right">
                  <MoovyLink
                    red
                    bold
                    onClick={() =>
                      setShowModalRemove({
                        name: getJoinedValue(row.lastName, row.firstName),
                        id: row.id
                      })
                    }
                  >
                    <FormattedMessage id="page.customers.tabAdministrators.tableAdministrators.button.remove" />
                  </MoovyLink>
                </TableCell>
              </MoovyRestricted>
            </TableRow>
          ))}
        </TableBody>
      </MoovyTable>
    </>
  )
}

export default TableAdministrators
