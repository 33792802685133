import {
  client,
  logoutUser,
  updateJwtTokenInInterceptor
} from '../moovyAxiosClient'

import { JwtPayload } from 'jwt-decode'
import { fetchAuthSession } from 'aws-amplify/auth'
import { updatePassword } from 'aws-amplify/auth'
import userpool from './userpool'
import { getBaseUrl } from '../index'
import { DISABLE_COGNITO_AUTHENTICATION } from '../../envConfig'

export interface AwsJwtPayload extends JwtPayload {
  given_name?: string
  family_name?: string
  name?: string
  email?: string
  email_verified?: boolean
  sub?: string
}

const logout = () => {
  if (DISABLE_COGNITO_AUTHENTICATION) {
    logoutUser()
    return Promise.resolve()
  }
  return new Promise((success, reject) => {
    const user = userpool.getCurrentUser()
    if (!user) {
      reject('Failed to get user')
    }
    user?.signOut()
    logoutUser()
    success(true)
  })
}

const setTokenToInterceptor = (jwtToken: string) => {
  updateJwtTokenInInterceptor(client, jwtToken)
}

const getAuthSession = () => {
  return fetchAuthSession()
}

const getJWTToken = (authSession: any) => {
  return authSession.tokens?.idToken?.toString()
}

const getUserGroups = (authSession: any) => {
  return authSession.tokens?.accessToken?.payload['cognito:groups']?.join(',')
}

const changePassword = (oldPassword: string, newPassword: string) => {
  return updatePassword({ oldPassword, newPassword })
}

const devLogin = (email: string) => {
  return client
    .get(`${getBaseUrl(false)}/dev/get-token`, {
      params: {
        email
      }
    })
    .then((response) => response.data.token)
}

const LoginAPI = {
  logout,
  setTokenToInterceptor,
  getAuthSession,
  getJWTToken,
  changePassword,
  getUserGroups,
  devLogin
}

export default LoginAPI
