import { Box, Step, StepLabel, Stepper } from '@mui/material'
import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_INVITATIONS_FOR_USER,
  QUERY_PLACES_FOR_LOGIN
} from '../../apis/moovyQueryKeys'
import {
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerHeader,
  MoovyProgress
} from '../../components'
import React, { useEffect, useState } from 'react'

import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import CustomersAPI from '../../apis/customers/customersAPI'
import { FormattedMessage } from 'react-intl'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import StepLandlord from './stepLandlord'
import StepPlace from './stepPlace'
import { appRoutes } from '../../app/permissions'
import { assertExhaustive } from '../../utils/utils'
import useAuth from '../../hooks/useAuth'
import useCustomerData from '../../hooks/useCustomerData'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import InvitationsAPI from '../../apis/invitations/invitationsAPI'

const LoginSteps = () => {
  const navigate = useNavigate()
  enum eStep {
    STEP_LANDLORD,
    STEP_PLACE
  }
  type WizardStep = eStep.STEP_LANDLORD | eStep.STEP_PLACE

  const { customer } = useCustomerData()
  const { admin } = useAuth()
  const [activeStep, setActiveStep] = useState<WizardStep>(
    customer?.id ? eStep.STEP_PLACE : eStep.STEP_LANDLORD
  )
  const [completedSteps, setCompletedSteps] = useState<WizardStep[]>([])

  const queryPlaces = useQuery({
    queryKey: [QUERY_PLACES_FOR_LOGIN],
    queryFn: () => CustomersAPI.getPlacesByCustomer(admin, customer.id),
    staleTime: DEFAULT_CACHE_TIME_MS,
    enabled: !!customer?.id
  })

  const queryInvitations = useQuery({
    queryKey: [QUERY_INVITATIONS_FOR_USER],
    queryFn: () => InvitationsAPI.getUserInvitations(),
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const isAllowedToCreateCustomer = queryInvitations.data?.find(
    (invitation) => !invitation.used && !invitation.landlordId
  )

  // If the user has no customer relationship defined and no open invitation to create one -> can't access Lupapalvelu
  const userHasNoRightsToContinue = !isAllowedToCreateCustomer && !customer.id

  useEffect(() => {
    const response = queryPlaces.data
    if (
      response &&
      response.length > 0 &&
      response[0]?.id &&
      response[0]?.name
    ) {
      navigate(appRoutes.ROOT, { replace: true })
    }
  }, [queryPlaces.isSuccess])

  if (userHasNoRightsToContinue) {
    return (
      <MoovyContainer>
        <MoovyContainerHeader>
          <Box sx={{ mt: '2rem' }}>
            <FormattedMessage id="page.steps.noRights.header" />
          </Box>
        </MoovyContainerHeader>
        <MoovyContainerContent>
          <Box sx={{ pt: '1rem' }}>
            <p>
              <FormattedMessage id="page.steps.noRights.content" />
            </p>
          </Box>
        </MoovyContainerContent>
      </MoovyContainer>
    )
  }

  const stepCompleted = (step: WizardStep) => {
    !completedSteps.includes(step) &&
      setCompletedSteps([...completedSteps, step])
    switch (step) {
      case eStep.STEP_LANDLORD:
        setActiveStep(eStep.STEP_PLACE)
        break
      case eStep.STEP_PLACE:
        navigate(appRoutes.ROOT, { replace: true })
        break
      default:
        assertExhaustive(step)
    }
  }

  type StepContentProps = { step: WizardStep }
  const StepContent = ({ step }: StepContentProps) => {
    switch (step) {
      case eStep.STEP_LANDLORD:
        return (
          <StepLandlord
            onStepCompleted={() => stepCompleted(eStep.STEP_LANDLORD)}
          />
        )
      case eStep.STEP_PLACE:
        return (
          <StepPlace onStepCompleted={() => stepCompleted(eStep.STEP_PLACE)} />
        )
      default:
        assertExhaustive(step)
    }
  }

  const isLoading = () => {
    return queryPlaces.isFetching
  }

  const steps = [
    {
      label: <FormattedMessage id="page.steps.bar.landlord.title" />,
      step: eStep.STEP_LANDLORD,
      icon: <ContactsOutlinedIcon fontSize={'large'} />,
      description: <FormattedMessage id="page.steps.bar.landlord.description" />
    },
    {
      label: <FormattedMessage id="page.steps.bar.place.title" />,
      step: eStep.STEP_PLACE,
      icon: <LocationOnOutlinedIcon fontSize={'large'} />,
      description: <FormattedMessage id="page.steps.bar.place.description" />
    }
  ]

  return (
    <div>
      {isLoading() ? (
        <MoovyProgress />
      ) : (
        <MoovyContainer>
          <MoovyContainerContent>
            <Box sx={{ mt: '30px' }}>
              <Stepper alternativeLabel sx={{ width: '100%', pt: '50px' }}>
                {steps.map((item) => (
                  <Step
                    key={item.step}
                    active={activeStep === item.step}
                    disabled={activeStep !== item.step}
                    completed={completedSteps.includes(item.step)}
                  >
                    <StepLabel>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <div>
                          <h3 style={{ margin: '5px' }}>{item.label}</h3>
                        </div>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <div style={{ display: 'flex' }}>{item.icon}</div>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            pl={1}
                          >
                            {item.description}
                          </Box>
                        </Box>
                      </Box>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div
                style={{
                  padding: '50px'
                }}
              >
                <StepContent step={activeStep} />
              </div>
            </Box>
          </MoovyContainerContent>
        </MoovyContainer>
      )}
    </div>
  )
}

export default LoginSteps
