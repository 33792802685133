import {
  QUERY_CATALOGUES_FOR_LOGIN,
  QUERY_LANDLORDS_FOR_LOGIN,
  QUERY_PLACES_FOR_LOGIN
} from '../../apis/moovyQueryKeys'
import { CataloguesAPI } from '../../apis/catalogues/cataloguesAPI'
import CustomersAPI from '../../apis/customers/customersAPI'
import { useQuery } from '@tanstack/react-query'
import useAuth from '../../hooks/useAuth'
import { appRoutes } from '../../app/permissions'
import { useEffect } from 'react'
import useCustomerData from '../../hooks/useCustomerData'
import { useNavigate } from 'react-router-dom'

const useAuthenticatorData = ({
  customerId,
  queryCataloguesEnabled = false,
  queryPlacesEnabled = false,
  queryCustomersEnabled = false
}: {
  customerId: string
  queryCataloguesEnabled?: boolean
  queryPlacesEnabled?: boolean
  queryCustomersEnabled: boolean
}) => {
  const navigate = useNavigate()
  const { admin, customerType } = useAuth()
  const {
    setCustomerFromArray,
    customer,
    setPlaceFromArray,
    setPlaceFromCatalogue,
    setInitialized
  } = useCustomerData()
  const queryCatalogues = useQuery({
    queryKey: [QUERY_CATALOGUES_FOR_LOGIN, customerId],
    queryFn: () => CataloguesAPI.getCataloguesByCustomer(customerId),
    enabled: queryCataloguesEnabled && customerType === 'T'
  })

  const queryPlaces = useQuery({
    queryKey: [QUERY_PLACES_FOR_LOGIN],
    queryFn: () => CustomersAPI.getPlacesByCustomer(admin, customerId),
    enabled: queryPlacesEnabled && customerType === 'L'
  })

  const queryCustomers = useQuery({
    queryKey: [QUERY_LANDLORDS_FOR_LOGIN],
    queryFn: () =>
      admin
        ? CustomersAPI.getLandlords(true, { limit: 10 })
        : CustomersAPI.getCustomers(false, {
            limit: 10,
            sort: 'TYPE',
            direction: 'ASC'
          }),
    enabled: queryCustomersEnabled
  })

  useEffect(() => {
    if (queryCustomers.isSuccess) {
      const response = queryCustomers.data
      if (
        response.data &&
        response.data?.length > 0 &&
        response.data[0]?.id &&
        response.data[0]?.name
      ) {
        setCustomerFromArray(response.data, true)
      } else {
        setInitialized(true)
        navigate(appRoutes.LOGIN_STEPS, { replace: true })
      }
    }
  }, [queryCustomers.isSuccess])

  useEffect(() => {
    if (queryCatalogues.isSuccess) {
      const response = queryCatalogues.data
      if (
        response &&
        response.length > 0 &&
        response[0]?.place?.id &&
        response[0]?.place?.name
      ) {
        setPlaceFromCatalogue(response[0])
      }
    }
  }, [queryCatalogues.isSuccess])

  useEffect(() => {
    if (queryPlaces.isSuccess) {
      const response = queryPlaces.data
      if (
        response &&
        response.length > 0 &&
        response[0]?.id &&
        response[0]?.name
      ) {
        setPlaceFromArray(response)
      } else {
        setInitialized(true)
        navigate(appRoutes.LOGIN_STEPS, { replace: true })
      }
    }
  }, [queryPlaces.isSuccess])

  const waitingForCustomers = queryCustomers.isPending
  const waitingForPlaces =
    customerType === 'T'
      ? customer?.id && queryCatalogues.isPending
      : customer?.id && queryPlaces.isPending
  return {
    queryCustomers,
    queryCatalogues,
    queryPlaces,
    waitingForCustomers,
    waitingForPlaces
  }
}

export default useAuthenticatorData
