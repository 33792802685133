{
  "common.yes": "yes",
  "common.no": "no",
  "common.ok": "ok",

  "common.buttons.yes": "Yes",
  "common.buttons.add": "Add",
  "common.buttons.save": "Save",
  "common.buttons.edit": "Edit",
  "common.buttons.next": "Next",
  "common.buttons.complete": "Compelete",
  "common.buttons.cancel": "Cancel",
  "common.buttons.remove": "Remove",
  "common.buttons.update": "Update",
  "common.buttons.terminate": "Terminate",
  "common.buttons.back": "Back",
  "common.buttons.clear": "Clear",

  "common.actions.error": "An error occurred",
  "common.actions.add.error": "Adding failed",
  "common.actions.update.error": "Updating failed",
  "common.actions.get.error": "Failed to get data",
  "common.actions.remove.error": "Failed to remove data",

  "common.form.errors.empty": "The field cannot be empty.",
  "common.form.errors.email": "Enter a valid email.",
  "common.form.errors.phoneNumber": "Enter a valid phone number with country code.",
  "common.form.errors.licensePlate": "Enter a valid vehicle license plate.",
  "common.form.errors.businessId": "Enter a valid Business ID.",
  "common.form.errors.number": "Only numbers can be entered in the field.",
  "common.form.errors.invalidDateTime": "Enter a valid date and time",

  "common.text.licensePlate": "License plate",
  "common.text.email": "Email",
  "common.text.project": "Project",
  "common.text.costCenter": "Cost center",
  "common.text.validityStart": "Start",
  "common.text.validityEnd": "End",

  "menu.invitations": "Invite a customer",
  "menu.allPlaces": "All locations",
  "menu.places": "Location & Rights",
  "menu.customer": "Customer details",
  "menu.reports": "Reports",
  "menu.user.detail": "Own details",
  "menu.user.logout": "Sign out",
  "menu.user.fi": "In Finnish",
  "menu.user.en": "In English",
  "menu.subHeader.moovyAdmin": "Moovy Admin",

  "appMenuTop.button.menu": "Menu",
  "appMenuTop.button.closeMenu": "Close menu",

  "validation.code.NOT_EMPTY": "{field, plural, one {Field cannot be empty.} other {{field} field cannot be empty.}}",
  "validation.code.MAX_LENGTH": "{field, plural, one {Too many characters} other {There are too many characters in the field {field}.}}",
  "validation.code.MIN_LENGTH": "{field, plural, one {There are no enough characters.} other {There are no enough characters in the field {field}.}}",
  "validation.code.BUSINESS_ID_FORMAT": "{field, plural, one {Input content is not in a valid format.} other {The field {field} content is not a valid format.}}",
  "validation.code.generic": "Filled values are not valid. Please check them.",
  "validation.field.name": "Name",
  "validation.field.streetAddress": "Address",
  "validation.field.city": "City",
  "validation.field.postalCode": "Postal code",
  "validation.field.businessId": "Business ID",
  "validation.field.costCenter": "Cost center",
  "validation.field.project": "Project",

  "sort.PLACES.NAME": "Name",
  "sort.PLACES.CITY": "City",
  "sort.PLACES.CAPACITY": "Capacity",
  "sort.PLACES.CUSTOMER_ID": "Customer",
  "sort.INVITATIONS.NAME": "Name",
  "sort.INVITATIONS.EMAIL": "Email",
  "sort.INVITATIONS.CREATED_AT": "Invited",
  "sort.INVITATIONS.USED": "Registered in",
  "sort.ACTIVE_RIGHTS.INDIVIDUAL": "Name",
  "sort.ACTIVE_RIGHTS.VEHICLE": "License plate",
  "sort.ACTIVE_RIGHTS.VALIDITY_START": "Valid from",
  "sort.ACTIVE_RIGHTS.VALIDITY_END": "Valid until",
  "sort.CATALOGUES.CUSTOMER_ID": "Customer",
  "sort.CATALOGUES.CREATED_AT": "Created",
  "sort.CATALOGUES.UPDATED_AT": "Updated",
  "sort.CATALOGUES.AMOUNT": "Amount",

  "api.error.code.OFFERING_AMOUNT_EXCEEDED_ERROR": "The offering must be at least the number of distributed rights.",
  "api.error.code.CUSTOMER_ALREADY_EXISTS": "A customer with the same name already exists in the system.",
  "api.error.code.INVITATION_ALREADY_EXISTS": "An invitation already exists for the same person.",
  "api.error.code.CATALOGUE_AMOUNT_EXCEEDED": "The amount is too large. The number of rights to be granted may not exceed the total number of rights for the product.",
  "api.error.code.CATALOGUE_AMOUNT_TOO_SMALL": "The amount is too small. The number of rights to be granted must be at least the same as the number of rights distributed.",
  "api.error.code.CATALOGUE_DOES_NOT_ALLOW_VEHICLE_GRANTING": "Catalogue doesn't allow granting rights to vehicles",
  "api.error.code.EMAIL_ALREADY_TAKEN": "The email is already in use.",
  "api.error.code.TOO_MANY_CATALOGUES_IN_REPORT": "The report includes too many catalogues. Change search filters and try again.",
  "api.error.code.TOO_MANY_RIGHTS_IN_REPORT": "The report includes too many rights. Change search filters and try again.",
  "api.error.code.RIGHT_HOLDER_EXCEEDS_CATALOGUE_AMOUNT": "Number of requested rights plus number of existing active rights must not exceed catalogue's limit",
  "api.error.code.generic": "An error occurred",

  "moovyErrorPage.oops": "Oops!",
  "moovyErrorPage.message": "Sorry, an unexpected error has occurred. Please, check the url and try to refresh the view again.",

  "components.modalSubPlace.actions.error.added": "Failed to add the location",
  "components.modalSubPlace.actions.added": "The location has been added.",
  "components.modalSubPlace.title": "Add sublocation",
  "components.modalSubPlace.button.add": "Add sublocation",
  "components.tableSubPlaces.header.name": "Name",
  "components.tableSubPlaces.header.customer": "Customer",
  "components.tableSubPlaces.header.streetAddress": "Street address",
  "components.tableSubPlaces.header.postalCode": "Postal code",
  "components.tableSubPlaces.header.city": "City",
  "components.tableSubPlaces.header.capacity": "Capacity",
  "components.tableSubPlaces.action.empty": "There are no locations",
  "components.tableSubPlaces.action.failed": "Failed to get locations",

  "page.steps.bar.landlord.title": "Landlord details",
  "page.steps.bar.landlord.description": "Fill landlord details",
  "page.steps.bar.place.title": "Location details",
  "page.steps.bar.place.description": "Fill location details",
  "page.steps.landlord.title": "Landlord details",
  "page.steps.landlord.info": "Create a company in Moovy by entering the company information. Company and its representatives are responsible for the parking facility to be created.",
  "page.steps.landlord.actions.added": "The landlord {name} has been added.",
  "page.steps.place.title": "Location details",
  "page.steps.place.info": "Please fill in the basic information of the object. The information is mandatory, as it is used in Moovy, for example, to manage parking permits. You can later change and complete the item's information",
  "page.steps.place.actions.added": "The location {name} has been added.",
  "page.steps.noRights.header": "User account has no rights to use Lupapalvelu",
  "page.steps.noRights.content": "To use Lupapalvelu you need to have rights to manage locations.",

  "page.invitations.modalAddInvitation.landlord.title": "Invite a customer",
  "page.invitations.modalAddInvitation.tenant.title": "Add and invite an administrator",
  "page.invitations.invitation.actions.updated": "Invitation link {name} has been updated.",
  "page.invitations.formInvitation.label.firstName": "First name",
  "page.invitations.formInvitation.label.lastName": "Last name",
  "page.invitations.list.button.landlord.newInvitation": "Invite a customer",
  "page.invitations.list.button.tenant.newInvitation": "Add and invite an administrator",
  "page.invitations.list.button.remove": "Remove invitation",
  "page.invitations.list.title": "Invite new customers",
  "page.invitations.list.actions.added": "New invitation {name} has been added.",
  "page.invitations.list.actions.removed": "The invitation {name} has been removed.",
  "page.invitations.list.table.header.name": "Name",
  "page.invitations.list.table.header.invited": "Invited",
  "page.invitations.list.table.header.registeredIn": "Registered in",
  "page.invitations.list.table.action.empty": "There are no invitation links.",
  "page.invitations.list.table.action.failed": "Failed to get invitation links.",
  "page.invitations.list.card.header.registeredIn": "Registered in",
  "page.invitations.modalRemove.header": "Remove the invitation?",
  "page.invitations.modalRemove.content": "Are you sure you want to remove the invitation from the user {name}?",

  "page.catalogues.modalAddRightHolder.title": "Add right holder",
  "page.catalogues.modalAddRightHolder.button.add": "Add right holder",
  "page.catalogues.modalAddRightHolder.steps.bar.email": "Email",
  "page.catalogues.modalAddRightHolder.steps.bar.rightHolder": "Right holder details",

  "page.catalogues.modalAddVehicleRightHolder.title": "Add vehicle",

  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.customerDetails": "Customer details",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.firstName": "First name",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.lastName": "Last name",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.phoneNumber": "Phone number",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.productDetails": "Product details",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.licensePlateDetails": "A vehicle license plate",
  "page.catalogues.tabCatalogue.formIndividualRightHolder.label.validity": "Validity",
  "page.catalogues.tabCatalogue.modalFindRightHolder.info": "Enter an email address to create a new right holder or to check if the right holder already has a Moovy account.",
  "page.catalogues.tabCatalogue.modalFindRightHolder.form.label.email": "Email",
  "page.catalogues.tabCatalogue.modalFindRightHolder.actions.added": "New right holder {name} has been added.",
  "page.catalogues.tabCatalogue.formVehicleRightHolder.label.vehicleDetails": "A license plate of the vehicle",
  "page.catalogues.tabCatalogue.formVehicleRightHolder.label.validity": "Right validity",

  "page.catalogues.tabCatalogue.title.rightHolder": "Right holders",
  "page.catalogues.tabCatalogue.search.label": "Search right holders",
  "page.catalogues.tabCatalogue.search.invalid": "Please enter at least 3 characters",
  "page.catalogues.tabCatalogue.button.addIndividual": "Add a right holder",
  "page.catalogues.tabCatalogue.button.addVehicle": "Add a vehicle",
  "page.catalogues.tabCatalogue.rightHolders.card.header.place": "Location",
  "page.catalogues.tabCatalogue.rightHolders.card.header.validUntil": "Valid until",
  "page.catalogues.tabCatalogue.rightHolders.table.header.name": "Name",
  "page.catalogues.tabCatalogue.rightHolders.table.header.place": "Location",
  "page.catalogues.tabCatalogue.rightHolders.table.header.validFrom": "Valid from",
  "page.catalogues.tabCatalogue.rightHolders.table.header.validUntil": "Valid until",
  "page.catalogues.tabCatalogue.rightHolders.table.action.empty": "There are no right holders here.",
  "page.catalogues.tabCatalogue.rightHolders.table.action.failed": "Failed to read right holders.",

  "page.rightHolders.rightHolder.title": "Right holder details",
  "page.rightHolders.rightHolder.button.edit": "Edit right holder",
  "page.rightHolders.rightHolder.modalEditRightHolder.title.individual": "Edit right holder",
  "page.rightHolders.rightHolder.modalEditRightHolder.title.vehicle": "Edit license plate",
  "page.rightHolders.rightHolder.modalEditRightHolder.actions.update": "Right holder {name} has been updated.",
  "page.rightHolders.rightHolder.formEditRightHolder.label.firstName": "First name",
  "page.rightHolders.rightHolder.formEditRightHolder.label.lastName": "Last name",
  "page.rightHolders.rightHolder.formEditRightHolder.label.phoneNumber": "Phone number",
  "page.rightHolders.rightHolder.formEditRightHolder.info.product": "A parking permit is automatically added to the user:",
  "page.rightHolders.rightHolder.tab.rightsAndVehicles.title": "Rights and vehicles",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.createdAt": "Created",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.right": "Right",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.place": "Location",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.validFrom": "Valid from",
  "page.rightHolders.rightHolder.tab.vehicles.table.header.validUntil": "Valid until",
  "page.rightHolders.rightHolder.tab.vehicles.table.item.permanent": "Permanent",
  "page.rightHolders.rightHolder.tab.vehicles.table.action.empty": "There are no vehicles here.",
  "page.rightHolders.rightHolder.tab.vehicles.table.action.failed": "Failed to read vehicles.",
  "page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.location": "Location:",
  "page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.right": "Right:",
  "page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.startRight": "Valid from:",
  "page.rightHolders.rightHolder.tab.vehicles.table.mobileCard.endRight": "Valid until:",

  "page.places.modalAddPlace.title": "Add location",
  "page.places.modalAddPlace.button.add": "Add location",
  "page.places.formPlace.contentTitle": "Location details",
  "page.places.formPlace.label.customer": "Customer",
  "page.places.formPlace.label.name": "Name",
  "page.places.formPlace.label.streetAddress": "Street address",
  "page.places.formPlace.label.postalCode": "Postal code",
  "page.places.formPlace.label.city": "City",
  "page.places.formPlace.label.capacity": "Capacity",
  "page.places.list.button.newPlace": "New location",
  "page.places.list.title": "Locations",
  "page.places.list.actions.added": "The new location {name} has been added.",
  "page.places.list.table.header.name": "Name",
  "page.places.list.table.header.streetAddress": "Street address",
  "page.places.list.table.header.postalCode": "Postal code",
  "page.places.list.table.header.city": "City",
  "page.places.list.table.header.capacity": "Capacity",
  "page.places.list.table.header.customer": "Customer",
  "page.places.list.table.action.empty": "There are no locations.",
  "page.places.list.table.action.failed": "Failed to get locations.",

  "page.places.catalogueTabs.catalogue.header": "Rights",
  "page.places.catalogueTabs.invitations.header": "Administrators",
  "page.catalogues.tabCatalogue.cataloguePlaceInfo.title": "Location details",
  "page.catalogues.tabCatalogue.cataloguePlaceInfo.name": "Name",
  "page.catalogues.tabCatalogue.cataloguePlaceInfo.street": "Street address",
  "page.catalogues.tabCatalogue.cataloguePlaceInfo.capacity": "Spots",

  "page.catalogues.tabCatalogue.catalogueStatusInfo.title": "Snapshot",
  "page.catalogues.tabCatalogue.catalogueStatusInfo.grantAmount": "Rights to be granted",
  "page.catalogues.tabCatalogue.catalogueStatusInfo.grantedAmount": "Granted rights",
  "page.catalogues.tabCatalogue.catalogueStatusInfo.costCenter": "Cost center",
  "page.catalogues.tabCatalogue.catalogueStatusInfo.project": "Project",

  "page.place.offeringsTab.header": "Rights",
  "page.place.offeringsTab.infoText1": "The table shows offerings overview.",
  "page.place.offeringsTab.table.empty": "Place has no offerings",
  "page.place.offeringsTab.table.error": "Failed to load offerings.",
  "page.place.offeringsTab.table.overview": "Overview",
  "page.place.offeringsTab.table.timestamps": "Timestamps",
  "page.place.offeringsTab.table.product": "Product",
  "page.place.offeringsTab.table.catalogue.amount": "Rights granted:",
  "page.place.offeringsTab.table.offering.edit": "Edit offering",
  "page.place.actions.updated": "The location {name} has been updated.",
  "page.place.modalEditPlace.title": "Edit location",

  "page.placeRights.placeInformation.header": "Location Details",
  "page.placeRights.placeInformation.button.edit": "Edit location",
  "page.placeRights.placeInformation.address": "Address",
  "page.placeRights.placeInformation.capacity": "Capacity",
  "page.placeRights.placeInformation.offeringAmount": "Total rights",
  "page.placeRights.situationOverview.header": "Situation Overview",
  "page.placeRights.situationOverview.totalAmount": "Granted to customers",
  "page.placeRights.situationOverview.parkingEnforcement": "Parking enforcement",

  "page.customer.formSubPlace.title.location": "Location details",
  "page.customer.formSubPlace.title.space": "Amount of parking spots",
  "page.customer.formSubPlace.label.name": "Location name",
  "page.customer.formSubPlace.label.spots": "Amount of spots",
  "page.customer.formSubPlace.label.city": "City",
  "page.customer.formSubPlace.label.postalCode": "Postal code",
  "page.customer.formSubPlace.label.streetAddress": "Street address",

  "page.customers.formCustomer.contentTitle": "Customer details",
  "page.customers.formCustomer.label.name": "Customer name",
  "page.customers.formCustomer.label.businessId": "Finnish business ID",
  "page.customers.formCustomer.label.streetAddress": "Street address",
  "page.customers.formCustomer.label.postalCode": "Postal code",
  "page.customers.formCustomer.label.city": "City",
  "page.customers.formCustomer.errors.businessId": "The field has to contain a valid Finnish business ID.",

  "page.customers.customersInformation.name": "Name",
  "page.customers.customersInformation.streetAddress": "Street address",
  "page.customers.customersInformation.postalCodeAndCity": "Postal code and post office",
  "page.customers.customersInformation.businessId": "Finnish business ID",
  "page.customers.customersInformation.customerNumber": "Customer number",

  "page.customers.tabAdministrators.infoText": "Add organisation admin.",
  "page.customers.tabAdministrators.button.newAdminInvitation": "Add and invite admin",
  "page.customers.tabAdministrators.actions.added": "New invitation {name} has been added.",
  "page.customers.tabAdministrators.invitationsOngoing.link": "{count, plural, one {One waiting invitation.} other {{count} waiting invitations.}}",
  "page.customers.tabAdministrators.tableAdministrators.header.name": "Name",
  "page.customers.tabAdministrators.tableAdministrators.header.registeredIn": "Registered in",
  "page.customers.tabAdministrators.tableAdministrators.button.remove": "Remove admin",
  "page.customers.tabAdministrators.tableAdministrators.action.empty": "There are no other admins in this account.",
  "page.customers.tabAdministrators.tableAdministrators.action.failed": "Failed to get admins.",
  "page.customers.tabAdministrators.modalAdminInvitations.title": "Awaiting invitations",
  "page.customers.tabAdministrators.modalRemove.header": "Remove admin?",
  "page.customers.tabAdministrators.modalRemove.content": "Do you want to remove admin {name}?",
  "page.customers.tabAdministrators.modalRemove.actions.removed": "Admin has been removed.",

  "page.customer.actions.updated": "The customer {name} has been updated.",
  "page.customer.title": "Organization details",
  "page.customer.button.edit": "Edit customer",
  "page.customer.modalEditCustomer.title": "Edit customer",
  "page.customer.customerTabs.locations.header": "Locations",
  "page.customer.customerTabs.admins.header": "Admins",

  "page.offering.button.edit": "Edit offering",
  "page.offering.cataloguesTab.table.error": "Failed to load catalogues",
  "page.offering.cataloguesTab.header": "Catalogues",
  "page.offering.cataloguesTab.infoText1": "The table shows the total number of permits issued for the location.",
  "page.offering.cataloguesTab.infoText2": "You can issue permits to new customers by clicking on the \"Create catalogue\" button.",
  "page.offering.cataloguesTab.createCatalogue": "Create catalogue",
  "page.offering.cataloguesTab.table.catalogue.edit": "Edit catalogue",
  "page.offering.cataloguesTab.table.name": "Name",
  "page.offering.cataloguesTab.table.customer": "Customer",
  "page.offering.cataloguesTab.table.overview": "Overview",
  "page.offering.cataloguesTab.table.timestamps": "Timestamps",
  "page.offering.cataloguesTab.table.empty": "There are no catalogues there.",
  "page.offering.offeringInfo.title": "Details",
  "page.offering.offeringInfo.name": "Permit",
  "page.offering.offeringInfo.location": "Location",
  "page.offering.offeringInfo.catalogues.amount": "Granted permits for customers",
  "page.offering.offeringInfo.maximumAmount": "Maximum permits",
  "page.offering.offeringInfo.ignoreAmountLimits": "Unlimited",

  "page.reports.title": "Reports",
  "page.reports.type.radioGroup.title": "Choose report type",
  "page.reports.type.radioGroup.landlord": "Catalogues",
  "page.reports.type.radioGroup.tenant": "Rights",
  "page.reports.customer.title": "Choose customer",
  "page.reports.customer.label": "Customer",
  "page.reports.customer.all": "All",
  "page.reports.range.title": "Choose date range",
  "page.reports.submit.button": "Download CSV report",
  "page.reports.toast.error.emptyReport": "No report found by search criteria.",
  "page.reports.toast.error.csvFailed": "Failed to generate report.",
  "page.reports.daterange.start": "Start",
  "page.reports.daterange.end": "End",

  "page.users.title": "User",
  "page.users.button.edit": "Edit user",
  "page.users.label.user": "Logged user",
  "page.users.label.emailConfirmed": "Email confirmed",
  "page.users.modalChangePassword.title": "Update password",
  "page.users.modalChangePassword.button.add": "Update",
  "page.users.modalChangePassword.action.changed": "The password has been updated.",
  "page.users.formPassword.errors.password": "New and confirmed passwords need to be the same.",
  "page.users.formPassword.label.oldPassword": "Current password",
  "page.users.formPassword.label.newPassword": "New password",
  "page.users.formPassword.label.confirmNewPassword": "Confirm new password",

  "moovyParkingEnforcementStatus.yes": "Enforced",
  "moovyParkingEnforcementStatus.no": "Not enforced",

  "timeStampItems.createdAt": "Created at:",
  "timeStampItems.updatedAt": "Updated at:",

  "page.offering.modalCreateCatalogue.title": "Create catalogue",
  "page.offering.modalCreateCatalogue.grantedTo": "Who are rights granted to?",
  "page.offering.modalCreateCatalogue.grantedTo.customer": "Customer",
  "page.offering.modalCreateCatalogue.grantedTo.self": "Own company",
  "page.offering.modalCreateCatalogue.customerLabel": "Customer information",
  "page.offering.modalCreateCatalogue.customerName": "Organization name",
  "page.offering.modalCreateCatalogue.businessId": "Business ID",
  "page.offering.modalCreateCatalogue.project": "Project",
  "page.offering.modalCreateCatalogue.costCenter": "Cost center",
  "page.offering.modalCreateCatalogue.streetAddress": "Street address",
  "page.offering.modalCreateCatalogue.postalCode": "Postal code",
  "page.offering.modalCreateCatalogue.city": "City",
  "page.offering.modalCreateCatalogue.offering": "Granted offering",
  "page.offering.modalCreateCatalogue.catalogueName": "Catalogue name",
  "page.offering.modalCreateCatalogue.name": "Name",
  "page.offering.modalCreateCatalogue.amount": "Amount",
  "page.offering.modalCreateCatalogue.amount.minimum": "Amount must be 0 or greater",
  "page.offering.modalCreateCatalogue.allowVehicleGrant": "Allow to grant permission for vehicles",
  "page.offering.modalCreateCatalogue.mutation.success": "Catalogue creation succeeded",
  "page.offering.modalCreateCatalogue.mutation.conflict": "The number of granted rights cannot exceed the total number of rights for the product.",

  "page.offering.modalEditOffering.title": "Edit offering",
  "page.offering.modalEditOffering.product": "Product",
  "page.offering.modalEditOffering.amountLabel": "Amount",
  "page.offering.modalEditOffering.amount.minimum": "Amount must be 0 or greater",
  "page.offering.modalEditOffering.mutation.success": "Offering update succeeded",

  "page.offering.modalEditCatalogue.title": "Edit catalogue",
  "page.offering.modalEditCatalogue.catalogueName": "Catalogue name",
  "page.offering.modalEditCatalogue.customer": "Customer",
  "page.offering.modalEditCatalogue.offering": "Granted offering",
  "page.offering.modalEditCatalogue.amountLabel": "Amount of rights to grant",
  "page.offering.modalEditCatalogue.amount.minimum": "Amount must be 0 or greater",
  "page.offering.modalEditCatalogue.mutation.success": "Catalogue update succeeded",

  "modalEditVehicle.title": "Edit the vehicle's parking right",
  "modalEditVehicle.webshopWarning": "Parking right cannot be edited for webshop catalogues",
  "modalEditVehicle.actions.change": "The vehicle license plate has been changed.",
  "modalEditVehicle.actions.rightEnded": "The right has been terminated.",
  "modalEditVehicle.actions.error.end": "Failed to terminate the right.",
  "modalEditVehicle.modalRemove.header": "Terminate right?",
  "modalEditVehicle.modalRemove.content": "Are you sure you want to terminate the right from the vehicle {licensePlate}?",
  "modalEditVehicle.modalRemove.content.endReason": "Reason",
  "modalEditVehicle.modalRemove.content.endReason.placeholder": "Reason for termination",

  "moovySortAndFilter.sortingCriteria": "Sort",

  "allocatedRights.available": "Available:",
  "allocatedRights.rightsGranted": "Rights granted:",
  "allocatedRights.rightsShared": "Rights shared:",
  "allocatedRights.rightsTotal": "Total rights:",

  "page.right.title": "Right",
  "page.right.container.title": "Right information",
  "page.right.start": "Start",
  "page.right.end": "End",
  "page.right.licensePlate": "License plate",
  "page.right.place": "Place",
  "page.right.catalogue": "Catalogue",
  "page.right.individual": "Individual",
  "page.right.costCenter": "Cost center",
  "page.right.project": "Project",
  "page.right.endReason": "End reason",
  "page.right.active": "Active"
}
