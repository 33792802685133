import FormVehicleRightHolder, {
  DefaultRightHolderValues
} from '../formVehicleRightHolder'
import {
  GrantRightByLpnDTO,
  RightDTO
} from '../../../../../apis/rightHolders/dto'

import { BackendDatetime } from '../../../../../utils/datetimeUtils'
import { FormattedMessage } from 'react-intl'
import { MoovyDialog } from '../../../../../components'
import { MoovyToast } from '../../../../../utils/moovyToast'
import RightHoldersAPI from '../../../../../apis/rightHolders/rightHoldersAPI'
import { useMutation } from '@tanstack/react-query'

type SubmitModalCallback = (values: RightDTO) => void
type CancelModalCallback = () => void
export type ModalAddRightHolderProps = {
  customerId: string
  catalogueId: string
  open: boolean
  onSubmit: SubmitModalCallback
  onCancel: CancelModalCallback
  showAccountingFields?: boolean
}

const ModalAddRightHolder = (props: ModalAddRightHolderProps) => {
  const FORM_VEHICLE_RIGHT_HOLDER = 'formModalVehicleRightHolder'

  const createRightHolderMutation = useMutation({
    mutationFn: (dto: GrantRightByLpnDTO) =>
      RightHoldersAPI.grantRightToVehicle(dto),
    onSuccess: (response) => {
      const right: RightDTO = response.data || {}
      let name = right.vehicle?.licensePlate || ''

      MoovyToast.showToast(
        <FormattedMessage
          id="page.catalogues.tabCatalogue.modalFindRightHolder.actions.added"
          values={{ name }}
        />
      )
      props.onSubmit(right)
    }
  })

  const cancel = () => {
    createRightHolderMutation.reset()
    props.onCancel()
  }

  const createVehicleRightHolder = (values: DefaultRightHolderValues) => {
    const { licensePlate, validityStart, validityEnd, costCenter, project } =
      values

    const dto: GrantRightByLpnDTO = {
      licensePlate,
      right: {
        rightHolderType: 'vehicle',
        catalogueId: props.catalogueId,
        validityStart: BackendDatetime.toUTC(validityStart),
        validityEnd: validityEnd
          ? BackendDatetime.toUTC(validityEnd)
          : undefined,
        active: true,
        costCenter: costCenter?.trim() || undefined,
        project: project?.trim() || undefined
      }
    }
    createRightHolderMutation.mutate(dto)
  }

  return (
    <MoovyDialog
      open={props.open}
      onCancel={cancel}
      formReference={FORM_VEHICLE_RIGHT_HOLDER}
      mutation={createRightHolderMutation}
      errorTitleKey="common.actions.add.error"
      modalTitle={
        <FormattedMessage id="page.catalogues.modalAddVehicleRightHolder.title" />
      }
      submitButtonContent={<FormattedMessage id="common.buttons.save" />}
    >
      <FormVehicleRightHolder
        formReference={FORM_VEHICLE_RIGHT_HOLDER}
        onSubmit={createVehicleRightHolder}
        showAccountingFields={props.showAccountingFields}
      />
    </MoovyDialog>
  )
}

export default ModalAddRightHolder
