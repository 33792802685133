import {
  GrantRightByLpnDTO,
  GrantRightIndividualDTO,
  RightDTO,
  RightHolderCreateRightHolderDTO,
  RightHolderIndividualDTO,
  RightHolderResponseDTO,
  RightHolderUpdateDTO
} from './dto'

import { API_ROOT_URL } from '..'
import { client } from '../moovyAxiosClient'

const BASE_URL = `${API_ROOT_URL}/individuals`

const createIndividual = (rightHolder: RightHolderCreateRightHolderDTO) => {
  return client.post<RightHolderResponseDTO>(BASE_URL, rightHolder)
}

const updateIndividual = (
  rightHolderRef: string,
  rightHolder: RightHolderUpdateDTO
) => {
  return client.put<RightHolderResponseDTO>(
    `${BASE_URL}/${rightHolderRef}`,
    rightHolder
  )
}

const getIndividualByRef = (ref: string) => {
  return client
    .get<RightHolderResponseDTO>(`${BASE_URL}/${ref}`)
    .then((response) => response.data)
}

const getIndividualsByEmail = (email: string) => {
  return client
    .get<RightHolderIndividualDTO[]>(BASE_URL, {
      params: { email }
    })
    .then((response) => response.data)
}

const grantRightToIndividual = (ref: string, data: GrantRightIndividualDTO) => {
  return client.post<RightDTO>(`${BASE_URL}/${ref}/grant-right`, data)
}

const grantRightToVehicle = (data: GrantRightByLpnDTO) => {
  return client.post<RightDTO>(`${API_ROOT_URL}/vehicles/grant-right`, data)
}

const RightHoldersAPI = {
  createIndividual,
  updateIndividual,
  getIndividualByRef,
  getIndividualsByEmail,
  grantRightToIndividual,
  grantRightToVehicle
}

export default RightHoldersAPI
