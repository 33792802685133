import {
  CreateLandlordInvitationDTO,
  CreateTenantWithCatalogueInvitationDTO,
  InvitationResponseDTO,
  InvitationSortQueryParams,
  InvitationsPagingDTO
} from './dto'

import { client } from '../moovyAxiosClient'
import { getBaseUrl } from '..'

const BASE_URL = `invitations`
const getUrl = (admin: boolean) => `${getBaseUrl(admin)}/${BASE_URL}`

const createLandlordInvitation = (
  admin: boolean,
  invitation: CreateLandlordInvitationDTO
) => {
  return client.post<InvitationResponseDTO>(getUrl(admin), invitation)
}

const createTenantWithCatalogueInvitation = (
  invitation: CreateTenantWithCatalogueInvitationDTO
) => {
  return client.post<InvitationResponseDTO>(getUrl(false), invitation)
}

const removeInvitation = (invitationId: string) => {
  return client.delete<string>(`${getUrl(false)}/${invitationId}`)
}

const getInvitationsByCustomerId = (
  admin: boolean,
  id: string,
  includeUsed: boolean = false
) => {
  return client
    .get<InvitationResponseDTO[]>(`${getUrl(admin)}`, {
      params: { customerId: id, includeUsed }
    })
    .then((response) => response.data)
}

const getInvitations = (
  admin: boolean,
  queryParams: InvitationSortQueryParams | undefined
) => {
  return client
    .get<InvitationsPagingDTO>(
      getUrl(admin),
      queryParams
        ? {
            params: {
              ...queryParams
            }
          }
        : undefined
    )
    .then((response) => response.data)
}

const getUserInvitations = () =>
  client
    .get<InvitationResponseDTO[]>(`${getBaseUrl(false)}/user/invitations`)
    .then((response) => response.data)

const InvitationsAPI = {
  createTenantWithCatalogueInvitation,
  createLandlordInvitation,
  removeInvitation,
  getInvitationsByCustomerId,
  getInvitations,
  getUserInvitations
}

export default InvitationsAPI
