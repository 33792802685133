import FormIndividualRightHolder, {
  DefaultRightHolderValues
} from '../formIndividualRightHolder'
import {
  GrantRightIndividualDTO,
  RightHolderCreateIndividualDTO,
  RightHolderCreateRightHolderDTO
} from '../../../../../apis/rightHolders/dto'

import { OfferingNameIdDTO } from '../../../../../apis/offering/dto'
import { BackendDatetime } from '../../../../../utils/datetimeUtils'

export type StepAddRightHolderProps = {
  formReference: string
  customerId: string
  individualRightHolderId?: string
  catalogueId: string
  offering: OfferingNameIdDTO | undefined
  defaultValues?: DefaultRightHolderValues
  onSubmit: (
    values: RightHolderCreateRightHolderDTO | GrantRightIndividualDTO
  ) => void
}

const StepAddRightHolder = (props: StepAddRightHolderProps) => {
  const createRightHolder = (values: DefaultRightHolderValues) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      licensePlate,
      validityStart,
      validityEnd,
      catalogueId,
      project,
      costCenter
    } = values

    if (props.individualRightHolderId) {
      const dto: GrantRightIndividualDTO = {
        licensePlate,
        catalogueId,
        validityStart: BackendDatetime.toUTC(validityStart),
        validityEnd: validityEnd
          ? BackendDatetime.toUTC(validityEnd)
          : undefined,
        active: true,
        costCenter,
        project
      }
      props.onSubmit(dto)
    } else {
      const dto: RightHolderCreateIndividualDTO = {
        type: 'individual',
        customerId: props.customerId,
        firstName,
        lastName,
        email,
        phoneNumber,
        catalogueId,
        vehicles: [
          {
            licensePlate,
            grantRights: [
              {
                rightHolderType: 'vehicle',
                validityStart: BackendDatetime.toUTC(validityStart),
                validityEnd: validityEnd
                  ? BackendDatetime.toUTC(validityEnd)
                  : undefined,
                active: true,
                costCenter,
                project
              }
            ]
          }
        ]
      }
      props.onSubmit(dto)
    }
  }

  return (
    <FormIndividualRightHolder
      formReference={props.formReference}
      disableCustomerData={!!props.individualRightHolderId}
      offering={props.offering}
      catalogueId={props.catalogueId || ''}
      onSubmit={createRightHolder}
      defaultValues={props.defaultValues}
    />
  )
}

export default StepAddRightHolder
