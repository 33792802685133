import { FormattedMessage, useIntl } from 'react-intl'
import LoginAPI from '../../apis/authenticate/loginAPI'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerHeader,
  MoovyContainerSubHeader,
  MoovyDialog,
  MoovyMutationError
} from '../../components'
import React, { useState } from 'react'

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import FormPassword from './formPassword'
import { Grid } from '@mui/material'
import { MoovyToast } from '../../utils/moovyToast'
import { getLoggedName } from '../../utils/utils'
import useAuth from '../../hooks/useAuth'
import { useMutation } from '@tanstack/react-query'

const Users = () => {
  // TODO Antti: Is this needed somehow? Probably not.
  //const { user } = useAuthenticator((context) => [context.user])

  const { decodedToken } = useAuth()
  const intl = useIntl()
  const [showPasswordModal, setShowPasswordModal] = useState(false)

  const FORM_REFERENCE = 'formChangePassword'

  const changePasswordMutation = useMutation({
    mutationFn: (dto: { oldPassword: string; newPassword: string }) =>
      LoginAPI.changePassword(dto.oldPassword, dto.newPassword),
    onSuccess: (response: any) => {
      setShowPasswordModal(false)
      MoovyToast.showToast(
        <FormattedMessage id="page.users.modalChangePassword.action.changed" />
      )
    }
  })

  return (
    <>
      <MoovyContainer>
        <MoovyContainerHeader>
          <FormattedMessage id="page.users.label.user" />
        </MoovyContainerHeader>
        <MoovyContainerSubHeader
          title={getLoggedName(decodedToken!)}
          button={
            <MoovyButton
              startIcon={<EditRoundedIcon />}
              onClick={() => setShowPasswordModal(true)}
              showAsIconButton
              aria-label={intl.formatMessage({
                id: 'page.users.button.edit'
              })}
            />
          }
          divider
        />

        <MoovyContainerContent>
          <Grid container direction="row" gap={1}>
            <Grid item xs={3}>
              <b>
                <FormattedMessage id="common.text.email" />
              </b>
            </Grid>
            <Grid item xs={9}>
              {decodedToken?.email}
            </Grid>
            <Grid item xs={3}>
              <b>
                <FormattedMessage id="page.users.label.emailConfirmed" />
              </b>
            </Grid>
            <Grid item xs={9}>
              {decodedToken?.email_verified ? (
                <FormattedMessage id="common.yes" />
              ) : (
                <FormattedMessage id="common.no" />
              )}
            </Grid>
          </Grid>
        </MoovyContainerContent>
      </MoovyContainer>
      <MoovyDialog
        open={showPasswordModal}
        onCancel={() => {
          changePasswordMutation.reset()
          setShowPasswordModal(false)
        }}
        formReference={FORM_REFERENCE}
        errorTitleKey="common.actions.add.error"
        modalTitle={
          <FormattedMessage id="page.users.modalChangePassword.title" />
        }
        submitButtonContent={
          <FormattedMessage id="page.users.modalChangePassword.button.add" />
        }
      >
        <MoovyMutationError
          mutation={changePasswordMutation}
          errorTitleKey="common.actions.add.error"
        />
        <FormPassword
          onSubmit={(values) => changePasswordMutation.mutate(values)}
          formReference={FORM_REFERENCE}
        />
      </MoovyDialog>
    </>
  )
}

export default Users
