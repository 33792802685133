import React, { useEffect } from 'react'
import { MoovyErrorPage, MoovyProgress } from '../index'
import useAuth from '../../hooks/useAuth'
import useCustomerData from '../../hooks/useCustomerData'
import DevLogin from './DevLogin'
import useAuthenticatorData from './useAuthenticatorData'
import { jwtDecode } from 'jwt-decode'

const LocalAuthenticator = ({ children }: { children?: React.ReactNode }) => {
  const { isAuthenticated, setAuthentication, token } = useAuth()
  const { customer } = useCustomerData()

  const authenticatedAndCustomerFound = Boolean(isAuthenticated && customer.id)
  const { queryPlaces, queryCustomers, waitingForCustomers, waitingForPlaces } =
    useAuthenticatorData({
      customerId: customer?.id,
      queryCataloguesEnabled: authenticatedAndCustomerFound,
      queryPlacesEnabled: authenticatedAndCustomerFound,
      queryCustomersEnabled: isAuthenticated
    })

  // Need to set user groups again from token after possible reload
  useEffect(() => {
    if (isAuthenticated) {
      const decoded: any = jwtDecode(token)
      const userGroups = decoded ? decoded['cognito:groups']?.join(',') : []
      setAuthentication({
        userGroups,
        token
      })
    }
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return <DevLogin />
  }

  return (
    <>
      {!isAuthenticated || waitingForCustomers || waitingForPlaces ? (
        <MoovyProgress />
      ) : queryPlaces.isError || queryCustomers.isError ? (
        <MoovyErrorPage />
      ) : (
        children
      )}
    </>
  )
}

export default LocalAuthenticator
