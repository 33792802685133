import { UseQueryResult } from '@tanstack/react-query'
import { MoovyButton, MoovyForm } from '../../../components'
import { FormEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import { Grid } from '@mui/material'

const SearchRightHolders = ({
  initialSearch = '',
  onSearch,
  query
}: {
  initialSearch: string
  onSearch: (searchTerm: string) => void
  query: UseQueryResult<any, any>
}) => {
  const [search, setSearch] = useState(initialSearch)
  const intl = useIntl()

  const searchInvalid = search?.length === 1 || search?.length === 2

  const searchHandler = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    if (!searchInvalid) {
      onSearch(search)
    }
  }

  return (
    <div style={{ marginBottom: '2rem' }}>
      <form onSubmit={searchHandler} id="search">
        <Grid container direction="row">
          <Grid item>
            <MoovyForm.FormTextField
              name="searchTerm"
              data-testid="searchTerm"
              value={search}
              label={intl.formatMessage({
                id: 'page.catalogues.tabCatalogue.search.label'
              })}
              onChange={(evt) => {
                setSearch(evt.target.value)
              }}
              size="small"
              error={searchInvalid}
              errorText={
                searchInvalid
                  ? intl.formatMessage({
                      id: 'page.catalogues.tabCatalogue.search.invalid'
                    })
                  : ''
              }
              type="search"
            />
            <MoovyButton
              style={{ marginLeft: '1rem' }}
              query={query}
              type="submit"
              disabled={searchInvalid}
            >
              Hae
            </MoovyButton>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default SearchRightHolders
