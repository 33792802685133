import { Box, Step, StepLabel, Stepper } from '@mui/material'
import {
  MoovyButton,
  MoovyDialog,
  MoovyMutationError
} from '../../../../../components'
import { ReactNode, useEffect, useState } from 'react'
import {
  RightHolderCreateRightHolderDTO,
  RightHolderIndividualDTO,
  RightHolderResponseDTO,
  getRightHolderName,
  RightDTO,
  GrantRightIndividualDTO
} from '../../../../../apis/rightHolders/dto'
import { useMutation, useQuery } from '@tanstack/react-query'

import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import { FormattedMessage } from 'react-intl'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { MoovyToast } from '../../../../../utils/moovyToast'
import { OfferingNameIdDTO } from '../../../../../apis/offering/dto'
import { QUERY_INDIVIDUALS_GET_BY_EMAIL } from '../../../../../apis/moovyQueryKeys'
import RightHoldersAPI from '../../../../../apis/rightHolders/rightHoldersAPI'
import StepAddRightHolder from './stepAddRightHolder'
import StepFindRightHolder from './stepFindRightHolder'
import { assertExhaustive } from '../../../../../utils/utils'
import { DateTime } from 'luxon'

type SubmitModalCallback = (values: RightHolderResponseDTO | RightDTO) => void
type CancelModalCallback = () => void
export type ModalAddRightHolderProps = {
  customerId: string
  catalogueId: string
  offering: OfferingNameIdDTO | undefined
  open: boolean
  onSubmit: SubmitModalCallback
  onCancel: CancelModalCallback
}

const ModalAddRightHolder = (props: ModalAddRightHolderProps) => {
  type Step = 'STEP_FIND' | 'STEP_FILL'
  const [activeStep, setActiveStep] = useState<Step>('STEP_FIND')
  const [completedSteps, setCompletedSteps] = useState<Step[]>([])

  const [filledEmail, setFilledEmail] = useState('')
  const [givenEmail, setGivenEmail] = useState('')
  const [emailOptions, setEmailOptions] = useState<string[]>([])

  const [foundIndividual, setFoundIndividual] =
    useState<RightHolderIndividualDTO | null>(null)

  const FORM_REFERENCE_FIND = 'formModalIndividualsFind'
  const FORM_REFERENCE_FILL = 'formModalIndividualsFill'

  const queryIndividualsByEmail = useQuery({
    queryKey: [QUERY_INDIVIDUALS_GET_BY_EMAIL, givenEmail],
    queryFn: () => RightHoldersAPI.getIndividualsByEmail(givenEmail || ''),
    retry: false,
    enabled: !!givenEmail
  })

  const goToFillStep = () => {
    if (!completedSteps.includes('STEP_FIND')) {
      setCompletedSteps([...completedSteps, 'STEP_FIND'])
    }
    setActiveStep('STEP_FILL')
    setFilledEmail(givenEmail)
  }

  const goToFindStep = () => {
    setCompletedSteps([])
    setActiveStep('STEP_FIND')
  }

  useEffect(() => {
    if (!queryIndividualsByEmail.data || !queryIndividualsByEmail.isFetched) {
      return
    }
    if (queryIndividualsByEmail.data.length <= 0) {
      setFoundIndividual(null)
      setEmailOptions([])
      goToFillStep()
    } else if (queryIndividualsByEmail.data.length === 1) {
      setFoundIndividual(queryIndividualsByEmail.data[0])
      setEmailOptions(queryIndividualsByEmail.data.map((item) => item.email))
      goToFillStep()
    } else {
      setFoundIndividual(queryIndividualsByEmail.data[0])
      setEmailOptions(queryIndividualsByEmail.data.map((item) => item.email))
    }
  }, [queryIndividualsByEmail.fetchStatus])

  const createRightHolderMutation = useMutation({
    mutationFn: (dto: RightHolderCreateRightHolderDTO) =>
      RightHoldersAPI.createIndividual(dto),
    onSuccess: (response) => {
      const rightHolder: RightHolderResponseDTO = response.data || {}
      let name = ''

      const dataIndividual = rightHolder as RightHolderIndividualDTO
      name = getRightHolderName(dataIndividual)
      MoovyToast.showToast(
        <FormattedMessage
          id="page.catalogues.tabCatalogue.modalFindRightHolder.actions.added"
          values={{ name }}
        />
      )
      props.onSubmit(rightHolder)
    }
  })

  const grantRightMutation = useMutation({
    mutationFn: (dto: GrantRightIndividualDTO) =>
      RightHoldersAPI.grantRightToIndividual(foundIndividual?.id || '', dto),
    onSuccess: (response) => {
      const rightHolder: RightDTO = response.data || {}
      let name = ''
      name = rightHolder.vehicle?.licensePlate || ''
      MoovyToast.showToast(
        <FormattedMessage
          id="page.catalogues.tabCatalogue.modalFindRightHolder.actions.added"
          values={{ name }}
        />
      )
      props.onSubmit(rightHolder)
    }
  })

  const findEmail = (email: string) => {
    if (email && email === givenEmail) {
      goToFillStep()
    } else {
      setGivenEmail(email)
    }
  }

  const cancel = () => {
    createRightHolderMutation.reset()
    props.onCancel()
  }

  const steps: {
    label: ReactNode
    step: Step
    icon: ReactNode
  }[] = [
    {
      label: (
        <FormattedMessage id="page.catalogues.modalAddRightHolder.steps.bar.email" />
      ),
      step: 'STEP_FIND',
      icon: <ContactsOutlinedIcon fontSize={'large'} />
    },
    {
      label: (
        <FormattedMessage id="page.catalogues.modalAddRightHolder.steps.bar.rightHolder" />
      ),
      step: 'STEP_FILL',
      icon: <LocationOnOutlinedIcon fontSize={'large'} />
    }
  ]

  const StepContent = ({ step }: { step: Step }) => {
    switch (step) {
      case 'STEP_FIND':
        return (
          <StepFindRightHolder
            isFetching={queryIndividualsByEmail.isFetching}
            email={filledEmail ?? ''}
            selectableOptions={emailOptions}
            formReference={FORM_REFERENCE_FIND}
            onSubmit={({ email }) => findEmail(email)}
          />
        )
      case 'STEP_FILL':
        return (
          <StepAddRightHolder
            formReference={FORM_REFERENCE_FILL}
            customerId={props.customerId}
            offering={props.offering}
            catalogueId={props.catalogueId || ''}
            individualRightHolderId={foundIndividual?.id}
            defaultValues={{
              firstName: foundIndividual?.firstName ?? '',
              lastName: foundIndividual?.lastName ?? '',
              email: foundIndividual?.email ?? filledEmail ?? '',
              phoneNumber: foundIndividual?.phoneNumber ?? '',
              licensePlate: foundIndividual?.vehicles[0]?.licensePlate ?? '',
              catalogueId: props.catalogueId || '',
              validityStart: DateTime.local(),
              costCenter: '',
              project: ''
            }}
            onSubmit={(dto) =>
              foundIndividual
                ? grantRightMutation.mutate(dto as GrantRightIndividualDTO)
                : createRightHolderMutation.mutate(
                    dto as RightHolderCreateRightHolderDTO
                  )
            }
          />
        )
      default:
        assertExhaustive(step)
    }
  }

  return (
    <>
      <MoovyDialog
        open={props.open}
        onCancel={cancel}
        formReference={
          activeStep === 'STEP_FIND' ? FORM_REFERENCE_FIND : FORM_REFERENCE_FILL
        }
        mutation={createRightHolderMutation}
        errorTitleKey="common.actions.add.error"
        modalTitle={
          <FormattedMessage id="page.catalogues.modalAddRightHolder.title" />
        }
        submitButtonContent={
          activeStep === 'STEP_FIND' ? (
            <FormattedMessage id="common.buttons.next" />
          ) : (
            <FormattedMessage id="common.buttons.save" />
          )
        }
        leftSideButtons={
          completedSteps.includes('STEP_FIND')
            ? [
                <MoovyButton onClick={goToFindStep} variant="text" key={'back'}>
                  <FormattedMessage id="common.buttons.back" />
                </MoovyButton>
              ]
            : undefined
        }
      >
        <div
          style={{
            marginBottom: queryIndividualsByEmail?.error ? '1rem' : undefined
          }}
        >
          <MoovyMutationError
            query={queryIndividualsByEmail}
            errorTitleKey="common.actions.get.error"
          />
        </div>
        <Box sx={{ mt: '0px' }}>
          <Stepper
            alternativeLabel
            sx={{
              width: '100%',
              padding: '0'
            }}
          >
            {steps.map((item) => (
              <Step
                key={item.step.toString()}
                active={activeStep === item.step}
                completed={completedSteps.includes(item.step)}
                disabled={completedSteps.includes(item.step)}
              >
                <StepLabel>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <div>
                      <h3 style={{ margin: '5px' }}>{item.label}</h3>
                    </div>
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div
            style={{
              padding: '5px'
            }}
          >
            <StepContent
              step={
                completedSteps.includes('STEP_FIND') ? 'STEP_FILL' : 'STEP_FIND'
              }
            />
          </div>
        </Box>
      </MoovyDialog>
    </>
  )
}

export default ModalAddRightHolder
